<template>
  <v-footer
    tile
    flat
    height="80"
    class="font-weight-medium shadow-md"
    :color="$vuetify.theme.dark ? 'dark' : 'white'"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <v-col class="text-left" cols="12">
      <div class="d-flex flex-row justify-space-between align-center">
        <strong class="text--disabled">
          ©
          <img
            v-if="$vuetify.theme.dark"
            height="10"
            src="/img/logo/voy-dark.png"
            alt=""
          />
          <img v-else height="10" src="/img/logo/voy-light.png" alt="" />
          {{ new Date().getFullYear() }}
        </strong>
        <v-btn
          text
          x-small
          class="text--disabled"
          target="_blank"
          href="mailto:contato@ticketme.app"
        >
          Contato
        </v-btn>
      </div>
    </v-col>
  </v-footer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
};
</script>
